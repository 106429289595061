import api from './api'

const SUB_URL = '/moas'

export const searchMoAs = async (q: string) => {
    try {
        const response = await api.post(`${SUB_URL}/search`, {q: q});
        return response.data.results
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };
