import React, { useState } from "react"
import { Autocomplete, Loader } from "@mantine/core"
import { IoSearchOutline } from "react-icons/io5";
import classes from './SearchField.module.css';

interface SearchFieldProps {
    onSearch: (query: string) => Promise<Array<string>>;
    onOptionSelect: (option: string) => void;
    placeholder?: string;
    classNames?: any;
    styles?: any;
}

const SearchField: React.FC<SearchFieldProps> = ({onSearch, onOptionSelect, placeholder="", classNames, styles}) => {
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleQueryChange = async (value: string) => {
        setQuery(value);
        if (value) {
          setLoading(true);
          const results = await onSearch(value);
          setData(results);
          setLoading(false);
        } else {
          setData([]);
        }
    };

    return (
        <Autocomplete
          classNames={classNames}
          styles={styles}
          value={query}
          onChange={handleQueryChange}
          data={data}
          placeholder={placeholder}
          onOptionSubmit={(option) => {
            onOptionSelect(option);
            setData([]);
          }}
          rightSection={
            loading ? <Loader size="xs" /> : <IoSearchOutline size={16} />
          }
        />
      );
};

export default SearchField;
