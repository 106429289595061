import React, { useState } from "react"
import { MultiSelect, Loader, Text } from "@mantine/core"
import { IoFilter } from "react-icons/io5";
import classes from './MultiSearchField.module.css';

interface SearchFieldProps {
    onSearch: (query: string) => Promise<Array<string>>;
    onOptionSelect: (option: string) => void;
    placeholder?: string;
    classNames?: any;
    styles?: any;
}

const MultiSearchField: React.FC<SearchFieldProps> = ({onSearch, onOptionSelect, placeholder="", classNames, styles}) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleQueryChange = async (value: string) => {
        setQuery(value);
        if (value) {
          setLoading(true);
          const results = await onSearch(value);
          console.log(results)
          setData(results);
          setLoading(false);
        } else {
          setData([]);
        }
    };

    return (
        <MultiSelect
          searchable
          classNames={classNames}
          styles={styles}
          value={selectedValues}
          onChange={setSelectedValues}
          onSearchChange={handleQueryChange}
          data={data}
          placeholder={placeholder}
          onOptionSubmit={(option) => {
            onOptionSelect(option);
          }}
          rightSection={
            loading ? <Loader size="xs" /> : <IoFilter  size={16} />
          }
        />
      );
};

export default MultiSearchField;
